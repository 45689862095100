<template>
  <main v-if="show" class="mx-auto px-2 sm:px-4 lg:px-8 pt-10 pb-12 lg:pb-16 min-h-[700px] print:pt-0">
    <div class="container grid grid-cols-12 gap-2 print:hidden">
      <div class="col-span-12">
        <div class="flex justify-between text-2xl">
          We're sorry we have encountered an error. A member of our team has been notified. Please retry your request. If the
          issue persists, please contact your local Salvation Army. We are sorry for the inconvenience.
        </div>
      </div>
    </div>
  </main>
</template>
<script setup lang="ts">
import { useErrorHandlerStore } from '~/composables/stores/error-handler';

const errorHandlerStore = useErrorHandlerStore();
const show = computed(() => errorHandlerStore.showErrorMessage);
</script>
