<template>
  <div
    v-if="version != '' && versionStore.appVersion != '' && versionStore.appVersion != version"
    class="fixed inset-x-0 top-0 z-50 bg-black w-full bg-gradient-to-r from-[#050505a3] via-[#bd1818a3] to-[#050505a3] text-white font-proxima p-2 grid grid-cols-12 gap-4"
  >
    <div class="col-span-12 text-center py-4">
      Application updated while you were away. <button class="underline" @click="refreshPage">Click here</button> to get the
      latest change
    </div>
  </div>
</template>
<script setup lang="ts">
const versionStore = useVersionStore();

const version = ref('');
onMounted(async () => {
  versionStore.getVersion().then((_) => {
    version.value = _.data.latestRequiredVersion;
    versionStore.setAppVersion(version.value);
  });
});

const versionInterval = setInterval(async () => {
  const cookie = exportUserSession();
  if (cookie.value === undefined || cookie.value === null) {
    clearInterval(versionInterval);
    return;
  }
  versionStore.getVersion().then((_) => {
    version.value = _.data.latestRequiredVersion;
  });
}, 1000 * 60);

onUnmounted(() => {
  clearInterval(versionInterval);
});

const refreshPage = () => {
  versionStore.setAppVersion(version.value);
  location.reload();
};
</script>
