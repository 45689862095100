<template>
  <div class="min-h-screen flex flex-col" v-if="isMiddlewareLoading === false">
    <LayoutNewVesrion />
    <LayoutHeader />
    <div class="flex-1" v-if="isMiddlewareLoading">
      <LoadingOverlay :show="isMiddlewareLoading" />
    </div>
    <div class="flex-1" v-else>
      <SorryMessage />
      <slot v-if="!showError" />
      <!--      <VErrorBoundary :fall-back="SorryMessage">-->
      <!--      <slot /> -->
      <!--      </VErrorBoundary>-->
    </div>

    <LayoutFooter />
    <ClientOnly>
      <TimeoutModal />
    </ClientOnly>
    <LayoutToast />
    <LayoutChatNotification v-if="!hasOversight" />
    <LayoutFooterLicensedLocationStatusAlert @checkFsAlert="statusChecked = true" />
    <LayoutFooterFundingSourceAlert v-if="statusChecked" />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import SorryMessage from '~/components/layout/SorryMessage.vue';
import { useErrorHandlerStore } from '~/composables/stores/error-handler';

const userStore = useUserStore();

const { isMiddlewareLoading, hasOversight } = storeToRefs(userStore);

const errorHandlerStore = useErrorHandlerStore();
const showError = computed(() => errorHandlerStore.showErrorMessage);

const statusChecked = ref(false);
</script>
