<template>
  <Menu v-if="userStoreWithRefs.currentUser" as="div" class="relative ml-4 flex-shrink-0">
    <v-tooltip v-if="showProfile" :title="tooltip">
      <div>
        <MenuButton class="flex rounded-full text-sm">
          <img v-if="hasProfileImg" class="h-8 w-8 rounded-full border border-2 border-white" :src="profileImage" />
          <img v-else class="h-8 w-8 rounded-full border border-2 border-white" src="~/assets/images/nobody.png" />
          <span class="mx-2 mt-1.5 font-proxima text-white text-sm font-semibold"> Account </span>
          <Icon name="heroicons:chevron-down-20-solid" class="h-6 w-6 text-white mt-1.5 font-bold" />
        </MenuButton>
      </div>
    </v-tooltip>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <!-- <MenuItem v-slot="{ active }">
        <a href="https://dev.www.tsamm.org/profile.dpi" target="_blank" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
          <Icon name="heroicons:cog-6-tooth-solid" class="h-5 w-5 text-black font-bold" />
          Your Profile
        </a>
        </MenuItem>
        <MenuItem v-slot="{ active }">
          <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
            <Icon name="heroicons:list-bullet-20-solid" class="h-5 w-5 text-black font-bold" />
            Follow-up Tasks
          </a>
        </MenuItem>
        <MenuItem v-slot="{ active }">
          <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
            <Icon name="clarity:administrator-line" class="h-5 w-5 text-black font-bold" />
            System Admin
          </a>
        </MenuItem> -->
        <MenuItem v-slot="{ active }">
          <button
            @click="changeModule()"
            class="w-full text-left"
            :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
          >
            <Icon name="material-symbols:view-module-rounded" class="h-5 w-5 text-black font-bold" />
            Change Module
          </button>
        </MenuItem>
        <MenuItem v-slot="{ active }">
          <button
            class="w-full text-left"
            type="button"
            :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
            @click="logout()"
          >
            <Icon name="heroicons:lock-closed-20-solid" class="h-5 w-5 text-black font-bold" />
            Logout
          </button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useRuntimeConfig } from '#imports';
import { useUserStore } from '~/composables/stores/UserStore';
import { useSessionStore } from '~/composables/stores/session';

const config = useRuntimeConfig();
const userStoreWithRefs = storeToRefs(useUserStore());
const userStore = useUserStore();
const sessionStore = useSessionStore();

const hasProfileImg = computed(() => {
  return (
    userStoreWithRefs?.currentUser.value?.profile.profileImageBlobId !== undefined &&
    userStoreWithRefs?.currentUser.value?.profile.profileImageBlobId !== ''
  );
});

const profileImage = computed(() => {
  const blobId = userStoreWithRefs?.currentUser.value?.profile.profileImageBlobId;
  return `${config.public.legacyApiBase}/legacy/users/profileimg/${blobId}`;
});

const tooltip = computed(() => {
  if (!userStoreWithRefs?.currentUser) {
    return '';
  }
  return (
    `<div class="text-left whitespace-nowrap overflow-auto">${userStoreWithRefs?.currentUser.value?.profile.rankedUserName}</div>` +
    `<div class="text-left font-normal whitespace-nowrap overflow-auto">${userStoreWithRefs?.currentUser.value?.profile.apptDesc}</div>` +
    `<div class="text-left font-normal whitespace-nowrap overflow-auto">${userStoreWithRefs?.currentUser.value?.profile.locationName}</div>`
  );
});

const changeModule = () => {
  userStore.clearUser();
  userStore.setNotifChecked(false);
  sessionStore.stopCountdownTimer();
  clearUserSession();
  userStore.clearUser();
  window.location = config.public.moduleUrl;
};

const logout = () => {
  userStore.clearUser();
  userStore.setNotifChecked(false);
  sessionStore.logoutSession();
};

// Create a BroadcastChannel
const showProfile = ref(true);
const channel = new BroadcastChannel('app_channel');
channel.onmessage = async (event) => {
  if (event.data.type === 'login_update') {
    showProfile.value = false;
    await userStore.getCurrentUser();
    setTimeout(() => {
      showProfile.value = true;
    }, 100);
  }
};
</script>
