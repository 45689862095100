<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        v-for="notification in notifications"
        :key="notification.application_id"
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="font-proxima pointer-events-auto flex w-full max-w-md divide-x divide-gray-200 rounded-lg bg-white shadow-2xl ring-1 ring-green-700"
        >
          <div class="flex w-0 flex-1 items-center p-4">
            <div class="w-full">
              <p class="text-sm font-medium text-gray-900">Chat notification</p>
              <p class="mt-1 text-sm text-gray-500">
                You have a new chat response from {{ notification.applicant_name }}
                <button class="text-blue-500" @click="reply(notification.application_id)">
                  {{ generateApplicationId(notification.application_number) }}
                </button>
              </p>
            </div>
          </div>
          <div class="flex">
            <div class="flex flex-col divide-y divide-gray-200">
              <div class="flex h-0 flex-1">
                <button
                  type="button"
                  @click="reply(notification.application_id)"
                  class="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Reply
                </button>
              </div>
              <div class="flex h-0 flex-1">
                <button
                  type="button"
                  @click="closeNotification(notification.application_id)"
                  class="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';
import { ref } from 'vue';

const show = ref(true);
const notifications = ref<{ applicant_name: string; application_id: string; application_number: number }[]>([]);

const enabled = ref(true);
const route = useRoute();
let notifInterval: NodeJS.Timer | undefined;
document.addEventListener('visibilitychange', function () {
  const routeSequence = route.path.split('/');

  if (!document.hidden) {
    if (notifInterval) clearInterval(notifInterval);
    useSeoMeta({
      title: '',
    });
  }

  if (routeSequence[1] === 'applications' && routeSequence[3] === 'show') {
    enabled.value = true;
    return;
  }
  enabled.value = document.hidden ? false : true;
});

const applicationStore = useApplicationStore();
const { data, isFetching, refetch } = useQuery([`chat-notification`], () => applicationStore.getChatNotification(), {
  ...globalUseQueryOptions(),
  cacheTime: 0,
  staleTime: 0,
  enabled: enabled,
  onSuccess: (data) => {
    if (data.data.length > 0) {
      const routeSequence = route.path.split('/');

      if (notifInterval) clearInterval(notifInterval);
      useSeoMeta({
        title: '',
      });

      if (routeSequence[1] === 'applications' && routeSequence[3] === 'show' && document.hidden) {
        const appId = route.params.id;
        if (data.data.filter((item) => item.applicationId === appId).length) {
          let odd = true;

          notifInterval = setInterval(() => {
            const title = odd ? 'New Chat Message' : '';
            odd = !odd;
            useSeoMeta({
              title,
            });
          }, 1000);
        }
      } else {
        data.data.forEach((item) => {
          if (notifications.value.length < 6) {
            if (notifications.value.filter((i) => i.application_id === item.applicationId).length === 0) {
              notifications.value.push({
                applicant_name: item.applicantName,
                application_id: item.applicationId,
                application_number: item.applicationNumber,
              });
            }
          }
        });
      }
    }
  },
});
const chatInterval = setInterval(() => {
  const cookie = exportUserSession();
  if (cookie.value === undefined || cookie.value === null) {
    clearInterval(chatInterval);
    return;
  }
  if (enabled.value) {
    refetch();
  }
}, 10000);
onUnmounted(() => {
  clearInterval(chatInterval);
});

const closeNotification = (applicationId: string) => {
  notifications.value = notifications.value.filter((item) => item.application_id !== applicationId);
};

const reply = (id: string | undefined) => {
  if (useRoute().path === `/applications/${id}/show`) {
    notifications.value = notifications.value.filter((item) => item.application_id !== id);
    applicationStore.setScrollToChat();
  } else useRouter().push(`/applications/${id}/show?section=chat`);
};
</script>
