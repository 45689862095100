<template>
  <div class="ml-2">
    <TransitionRoot as="template" :show="openModal">
      <Dialog as="div" class="relative z-10">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl"
              >
                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    @click="openModal = false"
                  >
                    <icon name="heroicons:x-mark-solid" class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div>
                  <div class="px-4 pt-5 pb-4 sm:p-6">
                    <DialogTitle as="h3" class="font-trajan text-2xl text-red-600 mt-2 mb-2"
                      >Funding Source Notification
                    </DialogTitle>
                    <h1 class="font-bold">The following warnings are in effect based on your configurations:</h1>
                    <div class="pt-6" v-for="f in data.data" :key="f.fundingSourceId">
                      <span>Funding Source: </span>
                      <nuxt-link :to="`/funding-sources/${f.fundingSourceId}`" class="text-blue-500 mr-1 font-bold"
                        >{{ f.fundingSourceName }}
                      </nuxt-link>
                      <div>
                        Warning:
                        <span v-if="f.alertType === 'LowBalance'">
                          <v-tooltip title="Low Balance">
                            <icon
                              name="streamline:money-cash-bag-dollar-bag-payment-cash-money-finance"
                              class="w-5 h-5 mr-1"
                            />
                          </v-tooltip>
                          Low Balance
                          <b>{{ f.threshold }}% remaining</b>
                        </span>
                        <span v-else>
                          <v-tooltip title="Expiration">
                            <icon name="material-symbols:event-available-sharp" class="w-5 h-5 mr-1" />
                          </v-tooltip>
                          Expiration
                          <b>{{ f.threshold }} days</b>
                        </span>
                      </div>
                      <div v-if="f.serviceAreaNames.length" class="pl-10 mt-2">
                        <h3 class="font-bold underline">Service Areas Affected</h3>
                        <div class="pl-10">
                          <ul>
                            <li v-for="s in f.serviceAreaNames" :key="s" class="list-decimal">
                              {{ s }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div v-else class="pl-10 mt-2">There are no Service Areas currently using this Funding Source.</div>
                    </div>
                  </div>
                  <div class="border-t border-gray-500 text-center py-6">
                    <button class="btn-secondary" @click="openModal = false">Close</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useQuery } from '@tanstack/vue-query';
import { useAdminStore } from '~/composables/stores/AdminStore';
import { TsammPermission } from '~/composables/Enums';

const openModal = ref(false);

const adminStore = useAdminStore();

const TsammPermissionUser = [
  TsammPermission.CanManageSAHelp,
  TsammPermission.SAHelpCaseManagementDirector,
  TsammPermission.SAHelpCaseManagement,
  TsammPermission.SAHelpSupport,
  TsammPermission.SAHelpPaymentProcessing,
];

const userStore = useUserStore();
const enabled = ref(userStore.getUserPermission().some((element) => TsammPermissionUser.includes(element)));

const { data, isLoading } = useQuery(
  ['admin', 'funding-source-conf', 'notif'],
  () => adminStore.getFundingSourceNotification(),
  {
    ...globalUseQueryOptions(),
    cacheTime: 0,
    staleTime: 0,
    enabled: !adminStore.notifChecked && enabled,
    onSuccess: (data) => {
      if (data.data.length > 0) {
        openModal.value = true;
      }
    },
  }
);
</script>
