<template>
  <div class="ml-2">
    <TransitionRoot as="template" :show="openModal">
      <Dialog as="div" class="relative z-10">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl"
              >
                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    @click="(openModal = false), $emit('checkFsAlert')"
                  >
                    <icon name="heroicons:x-mark-solid" class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div>
                  <div class="px-4 pt-5 pb-4 sm:p-6">
                    <p>
                      WARNING: Your SAHelp license is not active. You can configure all elements and functionality but
                      applicants will not have access to
                      <a class="text-blue-500 underline" href="https://www.sahelp.org/" target="_blank">www.SAHelp.org</a>
                      until your license has been activated.
                    </p>
                  </div>
                  <div class="border-t border-gray-500 text-center py-6">
                    <button class="btn-secondary" @click="(openModal = false), $emit('checkFsAlert')">Close</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useQuery } from '@tanstack/vue-query';
import { TsammPermission } from '~/composables/Enums';

const openModal = ref(false);
const emit = defineEmits(['checkFsAlert']);

const TsammPermissionUser = [
  TsammPermission.CanManageSAHelp,
  TsammPermission.SAHelpCaseManagementDirector,
  TsammPermission.SAHelpCaseManagement,
  TsammPermission.SAHelpSupport,
  TsammPermission.SAHelpPaymentProcessing,
  TsammPermission.DPISystemAdmin,
];

const userStore = useUserStore();
const enabled = ref(
  userStore.getUserPermission().some((element) => TsammPermissionUser.includes(element)) &&
    !userStore.getUserPermission().some((element) => element === TsammPermission.SAHelpOversight)
);

const { data, isLoading } = useQuery(['admin', 'funding-source-conf', 'notif'], () =>
  userStore.checkLicensedLocationStatus({
    ...globalUseQueryOptions(),
    cacheTime: 0,
    staleTime: 0,
    enabled: !userStore.notifChecked && enabled,
    onSuccess: (d: any) => {
      if (d.data === false) {
        openModal.value = true;
      } else {
        emit('checkFsAlert');
      }
    },
  })
);
</script>
