import { defineStore } from 'pinia';

export const useIntegrationStore = defineStore('integration', () => {
  const checkIntegrationEnabled = (integration: string) => {
    return $fetch<{ success: boolean; data: unknown }>(`/integrations/${integration}/enabled`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  return {
    checkIntegrationEnabled,
  };
});
